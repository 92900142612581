/* eslint-disable */
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';

const expenseList = () => import('@/pages-smartbase/expense/expenseList.vue');

const userList = () => import('@/pages-smartbase/user/userList.vue');
const publicSign = () => import('@/pages-smartbase/public/ProjectPublicSign.vue');


const salesOrderCreateV2 = () => import('@/pages-smartbase/salesOrder-v2/createSalesOrder-v2.vue');


//Paymment
const paymentCreate = () => import('@/pages-smartbase/payment/paymentCreate.vue');

const inventoryList = () => import('@/pages-smartbase/inventory/inventoryList.vue');
const inventoryView = () => import('@/pages-smartbase/inventory/inventoryView.vue');

// import pages

const NotAllowed = () => import('@/pages-smartbase/notAllowed.vue');
const NotFound = () => import('@/pages/error/NotFound.vue');
const AuthLogin = () => import('@/pages/auths/AuthLogin');
const quickBooksSummary = () => import('@/pages-smartbase/config/qbSummarySync.vue');

const dashboardView = () => import('@/pages-smartbase/dashboard/dashboardView.vue');

const salesOrderRefundView = () => import('@/pages-smartbase/salesOrder/refund/salesOrderRefundView.vue');
const salesOrderRefundCreate = () => import('@/pages-smartbase/salesOrder/refund/salesOrderRefundCreate.vue');


const registerSessionList = () => import('@/pages-smartbase/register/registerSessionList.vue');
const registerSessionView = () => import('@/pages-smartbase/register/registerSessionView.vue');
const registerSessionActive = () => import('@/pages-smartbase/register/RegisterSessionActive.vue');
const registerCloseTrackingWizard = () => import('@/pages-smartbase/register/registerCloseTrackingWizard.vue');


const salesOrderCreateReturn = () => import('@/pages-smartbase/salesOrder/return/salesOrderReturnCreate.vue');

const stockAdjustmentCreate = () => import('@/pages-smartbase/stock/stockAdjustmentCreate.vue');
const stockAdjustmentView = () => import('@/pages-smartbase/stock/stockAdjustmentView.vue');
const stockAdjustmentList = () => import('@/pages-smartbase/stock/stockAdjustmentList.vue');
const stockAdjustmentEdit = () => import('@/pages-smartbase/stock/stockAdjustmentEdit.vue');


const stockTransferCreate = () => import('@/pages-smartbase/stockTransfer/stockTransferCreate.vue');
const stockTransferView = () => import('@/pages-smartbase/stockTransfer/stockTransferView.vue');
const stockTransferList = () => import('@/pages-smartbase/stockTransfer/stockTransferList.vue');
const stockTransferEdit = () => import('@/pages-smartbase/stockTransfer/stockTransferEdit.vue');



const ProductList = () => import('@/pages-smartbase/product/productList.vue');
const ProductCreate = () => import('@/pages-smartbase/product/productCreate.vue')
const ProductEdit = () => import('@/pages-smartbase/product/productEdit.vue')
const ProductView = () => import('@/pages-smartbase/product/productView.vue')

const RelationshipEdit = () => import('@/pages-smartbase/relationship/relationshipEdit.vue')
const RelationshipView = () => import ('@/pages-smartbase/relationship/relationshipView.vue');
const relationshipListSupplier = () => import('@/pages-smartbase/relationship/relationshipListSupplier.vue')
const relationshipListCustomer = () => import('@/pages-smartbase/relationship/relationshipListCustomer.vue')
const RelationshipCreate = () => import('@/pages-smartbase/relationship/relationshipCreate.vue')

const purchaseOrderList = () => import('@/pages-smartbase/purchaseOrder/purchaseOrderList.vue')
const purchaseOrderCreate = () => import("@/pages-smartbase/purchaseOrder/purchaseOrderCreate.vue");
const purchaseOrderView= () => import("@/pages-smartbase/purchaseOrder/purchaseOrderView.vue");
const purchaseOrderEdit = () => import("@/pages-smartbase/purchaseOrder/purchaseOrderEdit.vue");


const salesOrderCreate = () => import("@/pages-smartbase/salesOrder/salesOrderCreate.vue");
const salesOrderView = () => import("@/pages-smartbase/salesOrder/salesOrderView.vue");
const salesOrderCreateInvoice = () => import("@/pages-smartbase/salesOrder/salesOrderCreateInvoice.vue");
const salesOrderList = () => import("@/pages-smartbase/salesOrder/salesOrderList.vue");

const salesOrderEdit = () => import("@/pages-smartbase/salesOrder/edit/salesOrderEdit.vue");
const salesOrderInvoiceCreate = () => import("@/pages-smartbase/salesOrder/invoice/salesOrderInvoiceCreate.vue");


const salesOrderPackCreate = () => import("@/pages-smartbase/salesOrder/pack/salesOrderPackCreate.vue");

const Report = () => import('@/pages-smartbase/reports/reports.vue')

const configView = () => import("@/pages-smartbase/config/configView.vue");

const paymentList = () => import("@/pages-smartbase/payment/paymentList.vue");


const employView = () => import("@/pages-smartbase/employ/employView.vue");
const employList = () => import("@/pages-smartbase/employ/employList.vue");

const serviceList = () => import("@/pages-smartbase/service/serviceList.vue");
const serviceView = () => import("@/pages-smartbase/service/serviceView.vue");



const projectList = () => import("@/pages-smartbase/project/projectList.vue");
const projectCreate = () => import("@/pages-smartbase/project/projectCreate.vue");
const projectView = () => import("@/pages-smartbase/project/projectView.vue");
const routes = [


  {
    path: '/public/project/:id/:code/toSign',
    name: 'Project Public Sign',
    component: publicSign
  },   
  {
    path: '/project/:id',
    name: 'Project View',
    component: projectView,
    meta: {
      permissionName : 'project.view'
    }
  },    
  {
    path: '/project/new',
    name: 'Project Create',
    component: projectCreate,
    meta: {
      permissionName : 'project.create'
    }
  },
  {
    path: '/expenses',
    name: 'Expense List',
    component: expenseList,
    meta: {
      permissionName : 'paymentexpense.list'
    }
  },
  {
    path: '/projects',
    name: 'Project List',
    component: projectList,
    meta: {
      permissionName : 'project.list'
    }
  },
  {
    path: '/service/:id',
    name: 'Service View',
    component: serviceView,
    meta: {
      permissionName : 'service.view'
    }
  },
  {
    path: '/services',
    name: 'Service List',
    component: serviceList,
      meta: {
        permissionName : 'service.list'
      }
  },
  {
    path: '/payment/new',
    name: 'Payment Create',
    component: paymentCreate,
      meta: {
      
      }
  },
  {
    path: '/employ/:id',
    name: 'Employ View',
    component: employView,
    meta: {
      permissionName : 'employ.view'
    }
  },
      {
    path: '/users',
    name: 'User List',
    component: userList,
  },
  {
    path: '/employees',
    name: 'Employ List',
    component: employList,
    meta: {
      permissionName : 'employ.list'
    }
  },
    {
    path: '/inventory/:id',
    name: 'Inventory View',
    component: inventoryView,
    meta: {
      permissionName : 'inventory.view'
    }
  },
    {
    path: '/payments',
    name: 'Payment List',
    component: paymentList,
    meta: {
      permissionName : 'paymentreceiving.list'
    }
  },
    {
    path: '/inventories',
    name: 'Inventory List',
    component: inventoryList,
    meta: {
      permissionName : 'inventory.list'
    }
  },
  {
    path: '/',
    name: 'Blank',
    component: dashboardView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: dashboardView
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Report,
    meta: {
      permissionName : 'reports.list'
    }
  },
    {
    path: '/notAllowed',
    name: 'Not Allowed',
    component: NotAllowed
  },
  
    {
    path: '/qbSummary',
    name: 'QB Summary',
    component: quickBooksSummary,
    meta: {
      permissionName : 'qb.sync_button'
    }
  },
  
  {
    path: '/register-session',
    name: 'Register Session List',
    component: registerSessionList,
    meta: {
      permissionName : 'register.menu'
    }
  },
  {
    path: '/register-session/active',
    name: 'Register Session Active',
    component: registerSessionActive,
    meta: {
      permissionName : 'register.menu'
    }
  },
  {
    path: '/register-session/:id',
    name: 'Register View session Active',
    component: registerSessionView,
    meta: {
      permissionName : 'register.menu'
    }
  },
  {
    path: '/register-session/:id/end',
    name: 'Register CLOSE session Active',
    component: registerCloseTrackingWizard,
    meta: {
      permissionName : 'register.menu'
    }
  },
  {
    path: '/stock-transfers',
    name: 'Stock transfers',
    component: stockTransferList,
    meta: {
      permissionName : 'stocktransfer.list'
    }
  },
  {
    path: '/stock-transfer/:id',
    name: 'Stock Transfer View',
    component: stockTransferView,
    meta: {
      permissionName : 'stocktransfer.view'
    }
  },
  {
    path: '/stock-transfer/:id/edit',
    name: 'Stock Transfer Edit',
    component: stockTransferEdit,
    meta: {
      permissionName : 'stocktransfer.update'
    }
  },
  
  {
    path: '/stock-transfer/new',
    name: 'Stock Transfer Create',
    component: stockTransferCreate,
    meta: {
      permissionName : 'stocktransfer.create'
    }
  },
  {
    path: '/stock-adjustments',
    name: 'Stock Adjustments',
    component: stockAdjustmentList,
    meta: {
      permissionName : 'stockadjustment.list'
    }
  },
  {
    path: '/stock-adjustment/new',
    name: 'Stock Adjustment Create',
    component: stockAdjustmentCreate,
    meta: {
      permissionName : 'stockadjustment.create'
    }
  },
  {
    path: '/stock-adjustment/:id',
    name: 'Stock Adjustment View',
    component: stockAdjustmentView,
    meta: {
      permissionName : 'stockadjustment.view'
    }
  },
  {
    path: '/stock-adjustment/:id/edit',
    name: 'Stock Adjustment Edit',
    component: stockAdjustmentEdit,
    meta: {
      permissionName : 'stockadjustment.update'
    }
  },
  
  {
    path: '/config/',
    name: 'config View',
    component: configView
  },
  {
    path: '/auths/auth-login',
    name: 'Auth Login',
    component: AuthLogin
  },
  {
    path: '/products/',
    name: 'Product List',
    component: ProductList,
    meta: {
      permissionName : 'product.list'
    }
  },
  {
    path: '/product/new',
    name: 'Product Create',
    component: ProductCreate,
    meta: {
      permissionName : 'product.create'
    }
  },
  {
    path: '/product/:id',
    name: 'Product View',
    component: ProductView,
    meta: {
      permissionName : 'product.view'
    }
  },
  {
    path: '/product/:id/edit',
    name: 'Product Edit',
    component: ProductEdit,
    meta: {
      permissionName : 'product.update'
    }
  },
  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: NotFound 
  },
  {
    path: '/relationships/Supplier',
    name: 'Relationship List Supplier',
    component: relationshipListSupplier,
    meta: {
      permissionName : 'relationship.list'
    }
  },
    {
    path: '/relationships/Customer',
    name: 'Relationship List Customer',
    component: relationshipListCustomer,
    meta: {
      permissionName : 'relationship.list'
    }
  },
  {
    path: '/relationship/new/:type',
    name: 'Relationship Create',
    component: RelationshipCreate,
    meta: {
      permissionName : 'relationship.create'
    }
  },
  {
    path: '/relationship/:id/edit',
    name: 'Relationship Edit',
    component: RelationshipEdit,
    meta: {
      permissionName : 'relationship.update'
    }
  },
  {
    path: '/relationship/:id',
    name: 'Relationship View',
    component: RelationshipView,
    meta: {
      permissionName : 'relationship.view'
    }
  },
  {
    path: '/purchaseOrders',
    name: 'Purchase Order List',
    component: purchaseOrderList
  },
  {
    path: '/purchaseOrder/new',
    name: 'Purchase Order New',
    component: purchaseOrderCreate
  },  
  {
    path: '/purchaseOrder/:id',
    name: 'Purchase Order View',
    component: purchaseOrderView
  },  
  {
    path: '/purchaseOrder/:id/edit',
    name: 'Purchase Order Edit',
    component: purchaseOrderEdit
  },  

  {
    path: '/salesOrder-v2/new',
    name: 'Sales Order New - V2',
    component: salesOrderCreateV2,
    meta: {
      permissionName : 'quicksale.create'
    }
  }, 

  {
    path: '/salesOrder/new',
    name: 'Sales Order New',
    component: salesOrderCreate,
    meta: {
      permissionName : 'salesorder.create'
    }
  }, 
  {
    path: '/salesOrder/:id',
    name: 'Sales Order View',
    component: salesOrderView,
    meta: {
      permissionName : 'salesorder.view'
    }
  },
  {
    path: '/salesOrder/:id/edit',
    name: 'Sales Order Edit',
    component: salesOrderEdit,
    meta: {
      permissionName : 'salesorder.update'
    }
  },
  {
    path: '/salesOrder/:id/refund',
    name: 'Sales Order Refund Create',
    component: salesOrderRefundCreate
  },
  {
    path: '/salesOrder/:id/refund/:refundId',
    name: 'Sales Order Refund Create View',
    component: salesOrderRefundView
  },
  
  {
    path: '/salesOrder/:id/invoice',
    name: 'Sales Order Invoice Create',
    component: salesOrderInvoiceCreate
  },
  {
    path: '/salesOrder/:id/return',
    name: 'Sales Order Create Return',
    component: salesOrderCreateReturn
  },
  {
    path: '/salesOrder/:id/invoice',
    name: 'Sales Order Create Invoice',
    component: salesOrderCreateInvoice
  },
  {
    path: '/salesOrder/:id/pack',
    name: 'Sales Order Create Pack',
    component: salesOrderPackCreate
  },

  
  {
    path: '/salesOrders',
    name: 'Sales Order List',
    component: salesOrderList,
    meta: {
      permissionName : 'salesorder.list'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

// this function dynamically change the route title of app and Redirect user to login page if not logged in
import {store, refreshSession} from '../state/store'
router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`

  if (to.path.includes('changelog')) {
    window.location = 'https://fvtec.notion.site/Changelog-Smartbase-d03ec738278a46038ee1df20b3353ae4?pvs=4'
    return;
  }
  const publicRoutes = ['/auths/auth-register', '/auths/auth-login','/auths/auth-reset'];
  if (publicRoutes.includes(to.path) || store.state.session.logged) {



    if (to.meta.permissionName) {
       
          var per = store.state.config.permissions;
          if (per && per.length >0) {
              if (!per.includes(to.meta.permissionName)) {
                next(`/notAllowed?permissionName=${to.meta.permissionName}`);
              } else {
                next();
            }
          } else {
             next();
          }

    } else {
      next();
    }

    

    
   
  
  }else{
    next('/auths/auth-login');
  }
})

export default router
